.main {
    /* text-align: center; */
    /* background-color: aqua; */
    /* height: 93.3vh; */
    padding: 1vw;
    height: fit-content;
}

.charts {
    width: 70vw;
    height: fit-content;
}

