.navbar {
    background-color: grey;
    position: sticky !important;
    top: 0;
    z-index: 10;
    background-image: url("../images/swimmingjellies.png");
    background-position: right 0 top 0;
    background-blend-mode: darken;
    color: white;
    background-size: 180vw;
}

.navbar-hidden {
    display: none;
}

.navbar .chakra-button:hover {
    background: #EDF2F75C;
}

.navbar option {
    color: black;
}

