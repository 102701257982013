.sidebody {
width: 5%;
height: 100vh; 
z-index: 10; 
background-color: grey;
transition: width .3s;
position: fixed;
background-image: url("../images/swimmingjellies.png");
background-position: right 25% top 0;
background-blend-mode: multiply; 
}

.sidebody-hidden {
    display: none;
}

.sidebartext {
    color: white;
    opacity: 0%;
    transition: opacity .2s;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    padding-left: 5px;
}

.sidebody:hover   {
    width: 10%;
}

.sidebody:hover .sidebartext {
    opacity: 100%;
}

.sidebarlinktext {
    color: white;
    border-radius: 2px;
    text-align: center;
}

.sidebarlinktext:hover {
    background-color: rgba(255, 255, 255, 0.363);
}

@media screen and (max-width: 1079px)
{
    .sidebody{
        display: none;
    }
}