/* scale bg image for tablets, rerender a smaller vrsn */

.auth {
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-image: url('../../assets/images/Splash-Image2.png');
}



#gclogo {
    border-radius: 5px;
    max-width: 100%;
    margin: 3px;
    width: 400px;
}

#authcontainer {

    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    max-width: 100%;

    padding: 15px;

    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: .5s;
    animation: slide 0.5s forwards;
    animation-delay: .3s;

    opacity: 0;
}

#loginmodal {
    
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    max-width: 100%;

    padding: 15px;

    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: .5s;
    animation: slide 0.5s forwards;
    animation-delay: .3s;

    opacity: 0;
}

#loginbtn {
    width: 100%;
    background-color: rgb(10, 102, 194);
    color: white;
    border: 0;
    border-radius: 5px;
    height: 30px;
    margin: 3px;

    opacity: 1;

}

#gcid {
    width: 100%;
    height: 30px;
    border: 0;
    border-radius: 5px;
    margin: 3px;
    text-align: center;
}

#uid {
    width: 100%;
    height: 30px;
    border: 0;
    border-radius: 5px;
    margin: 3px;
    text-align: center;
}

#pwd {
    width: 100%;
    height: 30px;
    border: 0;
    border-radius: 5px;
    margin: 3px;
    text-align: center;
}

#pwderr {
    width: 100%;
    height: 30px;
    border: 0;
    border-radius: 5px;
    margin: 3px;
    text-align: center;

    box-shadow: inset 0px 0px 0px 3px rgb(255, 0, 0);

    -webkit-animation: shake 0.5s forwards;
    -webkit-animation-delay: 0;
    animation: shake 0.5s forwards;
    animation-delay: 0;
}

.error {
    width: 100%;
    height: 30px;
    border: 0;
    border-radius: 5px;
    margin: 3px;
    text-align: center;

    box-shadow: inset 0px 0px 0px 3px rgb(255, 0, 0);

    -webkit-animation: shake 0.5s forwards;
    -webkit-animation-delay: 0;
    animation: shake 0.5s forwards;
    animation-delay: 0;
}

.form-group {
    margin-bottom: 0 !important;
}

#pwdeye {
    position: fixed;
    left: 358px;
    top: 319px;
}

@-webkit-keyframes slide {
    0% {
        top: 0;
        opacity: 0;
    }

    100% {
        top: 100;
        opacity: 1;
    }
}

@keyframes slide {
    0% {
        top: 0;
        opacity: 0;
    }

    100% {
        top: 100;
        opacity: 1;
    }
}

@-webkit-keyframes slideup {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes slideup {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@-webkit-keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}


#errorbox{
    background-color: rgb(255, 108, 108);
    color: white;
    position: fixed;
    top: 100%;
    left: 15%;
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;

    -webkit-animation: slideup 0.5s forwards;
    -webkit-animation-delay: .2s;
    animation: slideup 0.5s forwards;
    animation-delay: .2s;

    opacity: 0;

    text-align: center;
}


#errormsgbox{
    background-color: rgb(255, 108, 108);
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;

    -webkit-animation: slideup 0.5s forwards;
    -webkit-animation-delay: .2s;
    animation: slideup 0.5s forwards;
    animation-delay: .2s;

    opacity: 0;

    text-align: center;
}


#busybox{
    background-color: rgba(24, 142, 17, 0.952);
    color: white;
    position: fixed;
    top: 100%;
    left: 15%;
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;

    -webkit-animation: slideup 0.5s forwards;
    -webkit-animation-delay: .2s;
    animation: slideup 0.5s forwards;
    animation-delay: .2s;

    opacity: 0;

    text-align: center;
}

