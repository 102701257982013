#adminselectionui{
    display: flex;
    margin: 0 !important
}

#adminselectionui>Button {
    color: white !important;
    background-color: rgb(0 105 217) !important;
    border-color: rgb(0 105 217) !important;
    /* border-radius: 0px !important; */
}

#adminselectionui>Button:hover {
    background-color: rgb(118, 162, 255) !important
}

#admin-panel {
    margin: 5px;
}

::-webkit-scrollbar:not(.ag-theme-balham) {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
